<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <div mat-card-avatar class="chefs-special-avatar"></div>
      <mat-card-title>Chef's Special</mat-card-title>
      <mat-card-subtitle>Serving All Day Every Day</mat-card-subtitle>
    </mat-card-header>
    <div *ngIf="isXLarge(); else largeContentImage">
      <img
        src="/assets/img/banners/xlarge/product-banner-chefs-special-xl.webp"
        alt="breakfast-bring-in-new-customers" 
      />
    </div>
    <mat-card-content>
      <h1>Sustainable Growth</h1>
      <h2>Designed for human and animal enjoyment.</h2>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #largeContentImage>
  <div *ngIf="isLarge(); else mediumContentImage">
    <img
      src="/assets/img/banners/large/product-banner-chefs-special-l.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #mediumContentImage>
  <div *ngIf="isMedium(); else smallContentImage">
    <img
      src="/assets/img/banners/medium/product-banner-chefs-special-m.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #smallContentImage>
  <div *ngIf="isSmall(); else xSmallContentImage">
    <img
      src="/assets/img/banners/small/product-banner-chefs-special-s.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img
      src="/assets/img/banners/xsmall/product-banner-chefs-special-xs.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>