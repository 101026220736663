import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CampaignModel } from "src/app/models/campaign-model";
import { CampaignService } from "src/app/services/campaign.service";
import { UpdateCampaignState } from "./update-campaign.state";

const initialState: UpdateCampaignState = {
    loading: false,
    loaded: false,
    campaign: null,
    success: false,
    errorMessage: null,
};

@Injectable({
    providedIn: 'root'
})
export class UpdateCampaignComponentStore extends ComponentStore<UpdateCampaignState> {

    constructor(private readonly campaignService: CampaignService) {
        super(initialState);
    }

    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (loaded, loading, success, errorMessage) => ({
            loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : UpdateCampaignState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: UpdateCampaignState) => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    });

    readonly setLoaded = this.updater((state: UpdateCampaignState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateDetailState = this.updater((state: UpdateCampaignState, value: { campaign : CampaignModel }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            campaign: value.campaign
        };
    });

    readonly setError = this.updater((state: UpdateCampaignState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly updateCampaign = this.effect((detailParams$: Observable<{ companyId: string, campaignId: number, campaignStatus: string }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.campaignService.updateCampaign(params.companyId, params.campaignId, params.campaignStatus).pipe(
                map((campaign) => {
                    this.updateDetailState({campaign: campaign});
                    this.setLoaded();
                    this.setInitial();
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}