import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ActiveSubscriptionPlanOrderModel } from 'src/app/models/active-subscription-plan-order-model';
import { selectHasUnpaidInvoice } from 'src/app/stores/global/app.selectors';
import { AcknowledgeDialogComponent } from '../dialogs/acknowledge-dialog/acknowledge-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {  
  ngUnsubscribeState = new Subject<void>();
  
  @Input()
  currentSubscriptionPlanOrder : ActiveSubscriptionPlanOrderModel;
  
  @Output()
  onCancelSubscriptionPlanOrder = new EventEmitter();
 
  hasUnpaidInvoice : boolean;
  
  constructor(
    private router : Router, 
    private store: Store,
    private confirmationDialog: MatDialog,
    private acknowledgeDialog: MatDialog,
    ){}

  ngOnInit(): void {
    this.store.select(selectHasUnpaidInvoice).pipe(takeUntil(this.ngUnsubscribeState)).subscribe(hasUnpaidInvoice => {
      this.hasUnpaidInvoice = hasUnpaidInvoice;
    });
  }

  ngOnDestroy(){
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public goToPaymentProviderCustomerPortal() : void {
    this.router.navigate(['/billing/portal']);
  }

  public cancelCurrentSubscriptionPlanOrder() : void {
    if(this.hasUnpaidInvoice){
      this.acknowledgeDialog.open(AcknowledgeDialogComponent, {
        data: {
          title: "Unpaid Invoice Detected",
          message: "We can't cancel your subscription plan. You still have an unpaid invoice. Please pay the invoice first before cancelling your subscriptionplan.",
          data: "",
          confirm: "Ok"
        }
      });
    }
    else {
      const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
        data: {
          title: "Are you sure?",
          message: "This can't be undone after you pressed Confirm.\nAll your data (deals, campaigns, members, etc.) will be lost forever.\n\nDo you still want to cancel your subscriptionplan?",
          data: "",
          confirm: "Delete"
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onCancelSubscriptionPlanOrder.emit();
        }
      });
    }    
  }
}
