import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, takeUntil } from 'rxjs';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { AccountEmailNotTakenValidator } from 'src/app/core/validators/account-email-not-taken.validator';
import { MustMatch } from 'src/app/core/validators/must-match.validator';
import { RegexValidator } from 'src/app/core/validators/regex-validator';
import { emailRegex } from 'src/app/core/validators/validation-regex';
import { CreateUserModel } from 'src/app/models/create-user-model';
import { AccountEmailVerifyService } from 'src/app/services/account-email-verify.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { RegisterComponentStore } from 'src/app/stores/components/registration/register.component-store';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registrationForm: UntypedFormGroup;
  public signInContent = "If you already have a Planet VG® account, you can sign in.";

  ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef : MatDialogRef<SpinnerDialogComponent>;

  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountEmailVerifyService: AccountEmailVerifyService,
    private registerComponentStore: RegisterComponentStore,
    private snackBar: MatSnackBar,
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private spinnerService: SpinnerService
  ) { }

  get registrationContactDetailsForm() {
    return this.registrationForm.controls.registrationContactDetailsForm as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.registrationForm = this.formBuilder.group({
      registrationContactDetailsForm: this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [
          Validators.required,
          Validators.pattern(emailRegex)],
          [AccountEmailNotTakenValidator.createValidator(this.accountEmailVerifyService)]
        ],
        password: ['', [
          Validators.required,
          RegexValidator.createValidator(/\d/, { hasNumber: true }),
          RegexValidator.createValidator(/[A-Z]/, { hasCapitalCase: true }),
          RegexValidator.createValidator(/[a-z]/, { hasSmallNumber: true }),
          RegexValidator.createValidator(/[^a-zA-Z\d]/, { hasSpecialCase: true }),
          Validators.minLength(8)
        ]
        ],
        confirmPassword: ['', Validators.required],
      }, {
        validator: MustMatch("password", "confirmPassword")
      })
    });

    this.registerComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.registerComponentStore.setInitial();
        this.snackBar.open("Successfully registered. Please check your email in order to verify your account.", "Dismiss");
        this.router.navigate(['/registered']);
      }
    });

    this.registerComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.registerComponentStore.setInitial();
        this.snackBar.open(errorMessage, "Dismiss");
      }
    });

    this.registerComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  signIn() {
    this.oidcSecurityService.authorize();
  }

  onRegister(event: any) {
    if (this.registrationForm.valid) {
      this.showSpinner();

      var createUser = new CreateUserModel();
      createUser.firstName = this.registrationContactDetailsForm.value.firstName.trim();
      createUser.lastName = this.registrationContactDetailsForm.value.lastName.trim();
      createUser.email = this.registrationContactDetailsForm.value.email.trim();
      createUser.password = this.registrationContactDetailsForm.value.password.trim();

      this.registerComponentStore.createUser({ createUserModel: createUser });
    }
  }

  private showSpinner(){
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded : boolean){
    if(loaded && this.loadingSpinnerDialogRef !== null){
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
