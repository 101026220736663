<div class="container">
  <div [ngClass]="{
    'container-banner-xlarge': isXLarge(),
    'container-banner-large': isLarge(),
    'container-banner-medium': isMedium(),
    'container-banner-small': isSmall(),
    'container-banner-xsmall': isXSmall()
  }">
    <h1>Our chefs have prepared the right plan for you.</h1>
    <h2>
      Each plan gives the best deal and loyalty experience to your customers.
    </h2>
    <h2>
      Start building stronger and lasting connections with your customers.
    </h2>
    <h2>Enjoy growing your community sustainably.</h2>
  </div>
  <div class="container-divider">
    <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />
  </div>
</div>
