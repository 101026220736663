<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [ngClass]="{
      'container-content-xlarge': isXLarge(),
      'container-content-large': isLarge(),
      'container-content-medium': isMedium(),
      'container-content-small': isSmall(),
      'container-content-xsmall': isXSmall()
    }"
  >
    <div
      [ngClass]="{
        'content-xlarge': isXLarge(),
        'content-large': isLarge(),
        'content-medium': isMedium(),
        'content-small': isSmall(),
        'content-xsmall': isXSmall()
      }"
    >
      <h1>Let's start saving</h1>
      <h2>
        Planet VG is the climate conscious and animal friendliest way to engage
        with your customers by offering them deals and reward their loyalty.
      </h2>
      <button mat-raised-button color="primary" routerLink="/register">
        Start Today
      </button>
    </div>
  </div>
</div>
