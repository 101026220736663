import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignCreateComponent } from './core/components/campaign-create/campaign-create.component';
import { BillingPortalComponent } from './core/components/billing-portal/billing-portal.component';
import { StoreLocationCreateComponent } from './core/components/store-location-create/store-location-create.component';
import { BillingPageComponent } from './presentation/sub-pages/billing-page/billing-page.component';
import { CampaignPageComponent } from './presentation/sub-pages/campaign-page/campaign-page.component';
import { CampaignsPageComponent } from './presentation/sub-pages/campaigns-page/campaigns-page.component';
import { CompanyPageComponent } from './presentation/sub-pages/company-page/company-page.component';
import { UnauthorizedComponent } from './presentation/pages/security/unauthorized/unauthorized.component';
import { StoreLocationDetailPageComponent } from './presentation/sub-pages/store-location-detail-page/store-location-detail-page.component';
import { StoreLocationsPageComponent } from './presentation/sub-pages/store-locations-page/store-locations-page.component';
import { HomeComponent } from './presentation/pages/home/home.component';
import { HowDoesItWorkComponent } from './presentation/pages/how-does-it-work/how-does-it-work.component';
import { PricingComponent } from './presentation/pages/pricing/pricing.component';
import { RegisterComponent } from './presentation/pages/registration/register.component';
import { RegisteredComponent } from './presentation/pages/registered/registered.component';
import { CheckoutSuccessComponent } from './core/components/checkout-success/checkout-success.component';
import { CheckoutFailureComponent } from './core/components/checkout-failure/checkout-failure.component';
import { NavigationComponent } from './core/components/navigation/navigation.component';
import { PromotionsPageComponent } from './presentation/sub-pages/promotions-page/promotions-page.component';
import { PromotionCreateComponent } from './core/components/promotion-create/promotion-create.component';
import { PromotionPageComponent } from './presentation/sub-pages/promotion-page/promotion-page.component';
import { HomeRoutesGuard } from './auth/routerguards/home-routes.guard';
import { GettingStartedComponent } from './presentation/pages/getting-started/getting-started.component';
import { DashboardComponent } from './presentation/pages/dashboard/dashboard.component';
import { OidcCallbackComponent } from './presentation/pages/security/oidc-callback/oidc-callback.component';
import { CheckoutSuccessRoutesGuard } from './auth/routerguards/checkout-success-routes.guard';
import { PricingRoutesGuard } from './auth/routerguards/pricing-routes.guard';
import { AboutComponent } from './presentation/pages/about/about.component';
import { BlogsComponent } from './presentation/pages/blogs/blogs.component';
import { HelpCenterComponent } from './presentation/pages/help-center/help-center.component';
import { FaqComponent } from './presentation/pages/faq/faq.component';
import { CaseStudiesComponent } from './presentation/pages/case-studies/case-studies.component';
import { PrivacyPolicyComponent } from './presentation/pages/privacy-policy/privacy-policy.component';
import { UserTermsComponent } from './presentation/pages/user-terms/user-terms.component';
import { ClimatePledgeComponent } from './presentation/pages/climate-pledge/climate-pledge.component';
import { AccountComponent } from './core/components/account/account.component';
import { PartnerTermsComponent } from './presentation/pages/partner-terms/partner-terms.component';
import { ContactComponent } from './presentation/pages/contact/contact.component';
import { PortalRoutesGuard } from './auth/routerguards/portal-routes.guard';
import { ShoutOutsPageComponent } from './presentation/pages/shout-outs-page/shout-outs-page.component';
import { AccessibilityComponent } from './presentation/pages/accessibility/accessibility.component';

const routes: Routes = [
  {
    path: '', component: NavigationComponent, children: [
      // Public 
      { path: 'oidc-callback', component: OidcCallbackComponent },
      { path: 'about', component: AboutComponent },
      { path: 'blogs', component: BlogsComponent },
      { path: 'case-studies', component: CaseStudiesComponent },
      { path: 'help-center', component: HelpCenterComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'user-terms', component: UserTermsComponent },
      { path: 'partner-terms', component: PartnerTermsComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'climate-pledge', component: ClimatePledgeComponent },
      { path: 'shout-outs', component: ShoutOutsPageComponent },
      { path: 'how-it-works', component: HowDoesItWorkComponent },
      { path: 'pricing', component: PricingComponent, canActivate: [PricingRoutesGuard] },
      { path: 'register', component: RegisterComponent },
      { path: 'registered', component: RegisteredComponent },
      { path: 'accessibility', component: AccessibilityComponent },
      { path: 'home', component: HomeComponent, canActivate: [HomeRoutesGuard] },

      // Portal
      { path: 'dashboard', component: DashboardComponent, canActivate: [PortalRoutesGuard] },
      { path: 'getting-started', component: GettingStartedComponent, canActivate: [PortalRoutesGuard] },
      { path: 'checkout-success', component: CheckoutSuccessComponent, canActivate: [CheckoutSuccessRoutesGuard] },
      { path: 'checkout-failure', component: CheckoutFailureComponent, canActivate: [PortalRoutesGuard] },

      { path: 'company', component: CompanyPageComponent, canActivate: [PortalRoutesGuard] },

      { path: 'companies/:companyId/deals/create', component: PromotionCreateComponent, canActivate: [PortalRoutesGuard] },
      { path: 'companies/:companyId/deals/:promotionId', component: PromotionPageComponent, canActivate: [PortalRoutesGuard] },

      { path: 'companies/:companyId/campaigns/create', component: CampaignCreateComponent, canActivate: [PortalRoutesGuard] },
      { path: 'companies/:companyId/campaigns/:campaignId', component: CampaignPageComponent, canActivate: [PortalRoutesGuard] },

      { path: 'store-locations', component: StoreLocationsPageComponent, canActivate: [PortalRoutesGuard] },
      { path: 'companies/:companyId/store-locations/create', component: StoreLocationCreateComponent, canActivate: [PortalRoutesGuard] },
      { path: 'companies/:companyId/store-locations/:storeLocationId', component: StoreLocationDetailPageComponent, canActivate: [PortalRoutesGuard] },

      { path: 'deals', component: PromotionsPageComponent, canActivate: [PortalRoutesGuard] },
      { path: 'campaigns', component: CampaignsPageComponent, canActivate: [PortalRoutesGuard] },
      { path: 'billing', component: BillingPageComponent, canActivate: [PortalRoutesGuard] },
      { path: 'account', component: AccountComponent, canActivate: [PortalRoutesGuard] },
      { path: 'billing/portal', component: BillingPortalComponent, canActivate: [PortalRoutesGuard] },
      { path: '', redirectTo: "/home", pathMatch: "full" },
    ]
  },

  // Unauthorized pages
  { path: 'forbidden', component: UnauthorizedComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
