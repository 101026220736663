import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, filter, last, takeUntil } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BusinessAccountDetailComponentStore } from 'src/app/stores/components/business-account-detail/business-account-detail.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { ActiveSubscriptionException } from '../../exceptions/active-subscription-exception';
import { AcknowledgeDialogComponent } from '../dialogs/acknowledge-dialog/acknowledge-dialog.component';
import { ValidationException } from '../../exceptions/validation-exception';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeleteBusinessAccountModel } from 'src/app/models/delete-business-account-model';
import { clearSessionAction } from 'src/app/stores/global/app.actions';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  ngUnsubscribeState = new Subject<void>();
  businessAccount$ = this.store.select(selectBusinessAccount);

  constructor(
    private businessAccountDetailComponentStore: BusinessAccountDetailComponentStore,
    private store: Store,
    private confirmationDialog: MatDialog,
    private acknowledgeDialog: MatDialog,
    private oidcSecurityService: OidcSecurityService,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private router: Router,
  ) {
    // Nothing here
  }

  ngOnInit(): void {

    this.businessAccountDetailComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.businessAccountDetailComponentStore.setInitial();

        if (errorMessage instanceof ActiveSubscriptionException) {
          this.acknowledgeDialog.open(AcknowledgeDialogComponent, {
            data: {
              title: "Active subscription detected",
              message: "We can't delete your business account, you still have an active subscription. Please go to the subscription management section and cancel your subscription first.",
              data: "",
              confirm: "Ok"
            }
          });
        }

        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          const displayErrorMessage = 'Business account deleted failed.';
          this.snackBar.open(displayErrorMessage, "Dismiss");
        }
      }
    });

    this.businessAccountDetailComponentStore.deleteSuccess$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(result => {
      if (result) {
        this.snackBar.open("Business account deleted successfully", "Dismiss");
        setTimeout(() => this.logOffAfterDelete(), 1000);
      }
    });

    this.businessAccountDetailComponentStore.deleteLoaded$.pipe(takeUntil(this.ngUnsubscribeState), filter(loaded => loaded)).subscribe(loaded => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onDelete() {
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure?",
        message: "This can't be undone after you pressed Delete. We will cancel your current subscription and\nall your business data (campaigns, members, store locations) will be lost forever.\n\nDo you still want to delete your business account?",
        data: "",
        confirm: "Delete"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const deleteBusinessAccount = new DeleteBusinessAccountModel();
        this.businessAccount$.pipe(takeUntil(this.ngUnsubscribeState), last(), filter(result => Boolean(result))).subscribe(businessAccount => {
          this.showSpinner();
          deleteBusinessAccount.id = businessAccount.id;
          this.businessAccountDetailComponentStore.deleteBusinessAccount({ deleteBusinessAccount: deleteBusinessAccount });
        });
      }
    });
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null && this.loadingSpinnerDialogRef !== undefined) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }

  private logOffAfterDelete(): void {
    this.store.dispatch(clearSessionAction());
    this.oidcSecurityService.logoff().subscribe(_ => {
      this.oidcSecurityService.logoffLocal();
      this.router.navigate(['/home']);
    });
  }
}
