<div class="container">
  <mat-card
    appearance="outlined"
    class="column-card mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>Let’s start saving</mat-card-title>
            <img class="pvg-icon" src="/assets/img/planetvg_icon.png" alt="planetvg-logo">
        </mat-card-title-group>
    </mat-card-header>
    <div *ngIf="isXLarge(); else largeContentImage">
      <img
        src="/assets/img/banners/xlarge/product-banner-lets-start-saving-xl.webp"
        alt="breakfast-bring-in-new-customers" 
      />
    </div>
    <mat-card-content>
      <h2>
        Become a Partner and empower your customers to create a better world for
        humans and animals alike.
      </h2>
    </mat-card-content>
    
    <mat-card-actions class="container-card-actions">
      <button mat-raised-button color="primary" [routerLink]="['/register']">
        <mat-icon>diversity_1</mat-icon>
        Become Partner
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #largeContentImage>
  <div *ngIf="isLarge(); else mediumContentImage">
    <img
      src="/assets/img/banners/large/product-banner-lets-start-saving-l.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #mediumContentImage>
  <div *ngIf="isMedium(); else smallContentImage">
    <img
      src="/assets/img/banners/medium/product-banner-lets-start-saving-m.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #smallContentImage>
  <div *ngIf="isSmall(); else xSmallContentImage">
    <img
      src="/assets/img/banners/small/product-banner-lets-start-saving-s.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img
      src="/assets/img/banners/xsmall/product-banner-lets-start-saving-xs.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>