<div
  [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <mat-card class="mat-component-background mat-elevation-z2">
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>
              {{ companyPromotionSummary.promotionName }}
            </mat-card-title>
            <mat-card-subtitle>Deal</mat-card-subtitle>
        </mat-card-title-group>
    </mat-card-header>
    <mat-card-content class="container-card-content">
      <div
        echarts
        [options]="options"
        [ngClass]="{
          'chart-large': isLarge(),
          'chart-medium': isMedium(),
          'chart-small': isSmall(),
          'chart-xsmall': isXSmall()
        }"
      ></div>
    </mat-card-content>
  </mat-card>
</div>
