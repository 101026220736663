<div class="container">
  <mat-card
    appearance="outlined"
    class="column-card mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <div mat-card-avatar class="dinner-avatar"></div>
      <mat-card-title>Analytics</mat-card-title>
      <mat-card-subtitle
        >Serving Dinner : 16h - 22h </mat-card-subtitle
      >
    </mat-card-header>
    <div *ngIf="isXLarge(); else largeContentImage">
      <img
        src="/assets/img/banners/xlarge/product-banner-dinner-xl.webp"
        alt="breakfast-bring-in-new-customers" 
      />
    </div>
    <mat-card-content>
      <h1>Understand your Sales</h1>
      <h2>
        Analyze how your deals and loyalty campaigns are performing. See which
        deal or campaign is working best for your business.
      </h2>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #largeContentImage>
  <div *ngIf="isLarge(); else mediumContentImage">
    <img
      src="/assets/img/banners/large/product-banner-dinner-l.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #mediumContentImage>
  <div *ngIf="isMedium(); else smallContentImage">
    <img
      src="/assets/img/banners/medium/product-banner-dinner-m.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #smallContentImage>
  <div *ngIf="isSmall(); else xSmallContentImage">
    <img
      src="/assets/img/banners/small/product-banner-dinner-s.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img
      src="/assets/img/banners/xsmall/product-banner-dinner-xs.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>