import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampaignModel } from 'src/app/models/campaign-model';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  @Input()
  campaign: CampaignModel;

  @Output()
  onDeletePressed = new EventEmitter();

  @Output()
  onActivatePressed = new EventEmitter();

  @Output()
  onDeactivatePressed = new EventEmitter();

  ngOnInit(): void {
    // Do nothing
  }

  delete() {
    this.onDeletePressed.emit(null);
  }

  canShowActivateAction(): boolean {
    let result = this.campaign?.isInactive();
    return result;
  }

  canShowArchiveAction(): boolean {
    let result = this.campaign?.isActive() || this.campaign?.isPendingActivation();
    return result;
  }

  activate() {
    this.onActivatePressed.emit(null);
  }
}
