<div class="container" fxLayout="row" fxLayoutAlign="start">
    <div *ngIf="readMode;">
      <div class="container-promotion" fxFlex>
        <app-promotion
          [promotion]="(viewModel$ | async)?.promotion"
          (onDeletePressed)="onDelete($event)"
          (onActivatePressed)="onActivate($event)"
          (onDeactivatePressed)="onDeactivate($event)"
        ></app-promotion>
      </div>
    </div>
    <div class="container-promotion-logo">
      <app-promotion-logo [promotion]="(viewModel$ | async)?.promotion"></app-promotion-logo>
    </div>
  </div>