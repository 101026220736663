<div class="container" fxFlex fxLayout="row" fxLayoutAlign="start">
  <div *ngIf="readMode; else editMode">
    <div class="container-store-location">
      <app-store-location
        [storeLocation]="(viewModel$ | async)?.storeLocation"
        (onEditPressed)="onEdit($event)"
        (onDeletePressed)="onDelete($event)"
        (onActivatePressed)="onActivate($event)"
        (onDeactivatePressed)="onDeactivate($event)"
      ></app-store-location>
    </div>
  </div>
  <div fxLayout="column">
    <div class="container-store-user" *ngIf="showStoreUser">
      <app-store-user-details
        (onGenerateNewPassword)="onGenerateNewPassword($event)"
        [storeUser]="(storeUserViewModel$ | async)?.storeUser"
      ></app-store-user-details>
    </div>
  </div>
</div>

<ng-template #editMode>
  <div class="container-store-location">
    <app-store-location-edit
      [storeLocation]="(viewModel$ | async)?.storeLocation"
      [storeLocationDetailsFormGroup]="storeLocationDetailsFormGroup"
      (onCancelPressed)="onCancel($event)"
      (onSavePressed)="onSave($event)"
    ></app-store-location-edit>
  </div>
</ng-template>
