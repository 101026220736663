<div
  [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <div class="container-product-banner">
    <app-product-banner></app-product-banner>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <div class="container-menu">
      <h1>MENU</h1>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <app-product-banner-chefs-special></app-product-banner-chefs-special>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <app-product-banner-breakfast></app-product-banner-breakfast>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <app-product-banner-lunch></app-product-banner-lunch>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <app-product-banner-dinner></app-product-banner-dinner>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <mat-divider></mat-divider>
    <div class="container-menu">
      <h1>How we will serve you</h1>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div
    class="container-product-banner container-product-banner-extra-gap"
  >
    <app-product-banner-haute-cuisine></app-product-banner-haute-cuisine>
  </div>
  <div
    class="container-product-banner container-product-banner-extra-gap"
  >
    <app-product-banner-prepared></app-product-banner-prepared>
  </div>

  <div
    class="container-product-banner container-product-banner-extra-gap"
  >
    <app-product-banner-made-for-animals></app-product-banner-made-for-animals>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <mat-divider></mat-divider>
    <div class="container-divider">
      <img
        src="/assets/img/planetvg_icon.png"
        width="150px"
        alt="planetvg-logo"
      />
    </div>
    <mat-divider></mat-divider>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <app-product-banner-lets-start-saving></app-product-banner-lets-start-saving>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  >
    <app-product-banner-take-order></app-product-banner-take-order>
  </div>
</div>
<app-bottom-menu></app-bottom-menu>
