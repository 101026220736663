<div class="container">
  <div class="amuse-bouche-container">
    <h1>Start Saving Today</h1>
    <app-registration-testimonial-banner></app-registration-testimonial-banner>
    <app-registration-contact-details
      [registrationDetailsFormGroup]="registrationContactDetailsForm"
      (onRegisterPressed)="onRegister($event)"
    ></app-registration-contact-details>
  </div>
</div>
<app-bottom-menu></app-bottom-menu>
