<footer>
  <mat-divider></mat-divider>
  <div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }" >
      <span><a href="/"><img class="planetvg-banner" src="/assets/img/planetvg-banner.png"  alt="planetvg-banner"/></a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Customer Apps</span>
      <span><a href=""><img class="app-store-icon" src="/assets/img/google-play-badge.png" alt="Customer App - Google Play Store"></a></span>
      <span><a href=""><img class="app-store-icon" src="/assets/img/apple-app-store-badge.png" alt="Customer App - Apple App Store"></a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Store Apps</span>
      <span><a href=""><img class="app-store-icon" src="/assets/img/google-play-badge.png" alt="Store App - Google Play Store"></a></span>
      <span><a href=""><img class="app-store-icon" src="/assets/img/apple-app-store-badge.png" alt="Customer App - Apple App Store"></a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Support</span>
      <span><a href="#" mat-button routerLink="/faq">Frequenty Asked Questions</a></span>
      <span><a href="#" mat-button routerLink="/help-center">Help Center</a></span>
      <span><a href="#" mat-button routerLink="/accessibility">Accessibility</a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Legal</span>
      <span><a href="" mat-button routerLink="/user-terms">User Terms & Conditions</a></span>
      <span><a href="" mat-button routerLink="/partner-terms">Partner Terms & Conditions</a></span>
      <span><a href="" mat-button routerLink="/privacy-policy">Privacy Policy</a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Company</span>
      <span><a href="#" mat-button routerLink="/about">About</a></span>
      <span><a href="#" mat-button routerLink="/climate-pledge">Climate Pledge</a></span>
      <span><a href="#" mat-button routerLink="/shout-outs">Shout Outs</a></span>
      <span><a href="#" mat-button routerLink="/contact">Contact Us</a></span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div [ngClass]="{
    'container-trademarks-large': isLarge(),
    'container-trademarks-medium': isMedium(),
    'container-trademarks-small': isSmall(),
    'container-trademarks-xsmall': isXSmall()
  }" >
    <span>©️ {{ currentYear }} <a href="https://almostlevel5.com">Almost Level 5 B.V.</a> All rights reserved. Planet VG is a registered trademark of Almost Level 5 B.V., registered in the Netherlands and other countries.</span>
  </div>
</footer>
