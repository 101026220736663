import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/core/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { MaximumActiveCampaignsExceededException } from 'src/app/core/exceptions/maximum-active-campaigns-exceeded-exception';
import { NoActiveSubscriptionException } from 'src/app/core/exceptions/no-active-subscription-exception';
import { ValidationException } from 'src/app/core/exceptions/validation-exception';
import { CampaignModel } from 'src/app/models/campaign-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { DeleteCampaignComponentStore } from 'src/app/stores/components/campaign-delete/delete-campaign.component-store';
import { CampaignDetailComponentStore } from 'src/app/stores/components/campaign-detail/campaign-detail.component-store';
import { UpdateCampaignComponentStore } from 'src/app/stores/components/campaign-update/update-campaign.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.scss']
})
export class CampaignPageComponent implements OnInit {

  viewModel$ = this.campaignDetailComponentStore.viewModel$;
  readMode: boolean = true;

  companyId: string;

  ngUnsubscribeState = new Subject<void>();
  private campaign: CampaignModel;

  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private campaignDetailComponentStore: CampaignDetailComponentStore,
    private dialog: MatDialog,
    private deleteCampaignComponentStore: DeleteCampaignComponentStore,
    private updateCampaignComponentStore: UpdateCampaignComponentStore,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
        const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
        const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
        if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
          this.router.navigate(['/getting-started']);
        }
        else {
          this.companyId = params.companyId;
          this.showSpinner();
          this.campaignDetailComponentStore.getCampaign({ companyId: params.companyId, campaignId: params.campaignId });
        }
      });
    });

    this.viewModel$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result.loaded))).subscribe(viewModel => {
      this.campaign = viewModel.campaign;
    });

    this.deleteCampaignComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.resetComponentStores();
        this.snackBar.open("Campaign archived successfully", "Dismiss");
        this.router.navigate(['/campaigns']);
      }
    });

    this.deleteCampaignComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.resetComponentStores();
        if(errorMessage instanceof ValidationException){
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.updateCampaignComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.resetComponentStores();
        this.snackBar.open("Campaign updated successfully", "Dismiss");
        this.router.navigate(['/campaigns']);
      }
    });

    this.updateCampaignComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.resetComponentStores();

        let displayErrorMessage = "Something went wrong.";
        if (errorMessage instanceof NoActiveSubscriptionException) {
          displayErrorMessage = 'No active subscriptionplan order. Please buy a subscriptionplan first.';
        }
        else if (errorMessage instanceof MaximumActiveCampaignsExceededException) {
          displayErrorMessage = 'You have reached the maximum amount of active campaigns with your current subscription. If you want more active campaigns, consider upgrading your subscriptionplan.';
        }
        if(errorMessage instanceof ValidationException){
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(displayErrorMessage, "Dismiss");
        }
      }
    });

    this.campaignDetailComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.updateCampaignComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.deleteCampaignComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onDelete(event: any) {
    this.resetComponentStores();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure?",
        message: "You want to archive this campaign",
        data: this.campaign.name,
        confirm: "Archive"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner();
        this.deleteCampaignComponentStore.deleteCampaign({ companyId: this.campaign.company.id, campaignId: this.campaign.id });
      }
    });
  }

  onActivate(event: any) {
    this.resetComponentStores();
    this.showSpinner();
    this.updateCampaignComponentStore.updateCampaign({ companyId: this.campaign.company.id, campaignId: this.campaign.id, campaignStatus: "ACTIVE" });
  }

  private resetComponentStores() {
    this.deleteCampaignComponentStore.setInitial();
    this.updateCampaignComponentStore.setInitial();
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
