import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthConfigModule } from './auth/auth-config.module';
import { MaterialModule } from './material-module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { extModules } from './build-specifics';
import { BottomMenuComponent } from './core/components/bottom-menu/bottom-menu.component';
import { MenuComponent } from './core/components/menu/menu.component';
import { UnauthorizedComponent } from './presentation/pages/security/unauthorized/unauthorized.component';
import { HomeComponent } from './presentation/pages/home/home.component';
import { BillingPageComponent } from './presentation/sub-pages/billing-page/billing-page.component';
import { TrimValueAccessorDirective } from './core/directives/trim.directive';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { appReducer } from './stores/global/app.reducer';
import { BusinessAccountEffects } from './stores/global/business-account.effects';
import { CompanyPageComponent } from './presentation/sub-pages/company-page/company-page.component';
import { CompanyEditComponent } from './core/components/company-edit/company-edit.component';
import { CompanyComponent } from './core/components/company/company.component';
import { StoreLocationsPageComponent } from './presentation/sub-pages/store-locations-page/store-locations-page.component';
import { StoreLocationDetailPageComponent } from './presentation/sub-pages/store-location-detail-page/store-location-detail-page.component';
import { StoreLocationComponent } from './core/components/store-location/store-location.component';
import { StoreLocationEditComponent } from './core/components/store-location-edit/store-location-edit.component';
import { ConfirmationDialogComponent } from './core/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { StoreLocationCreateComponent } from './core/components/store-location-create/store-location-create.component';
import { CampaignCreateComponent } from './core/components/campaign-create/campaign-create.component';
import { CampaignsPageComponent } from './presentation/sub-pages/campaigns-page/campaigns-page.component';
import { CampaignComponent } from './core/components/campaign/campaign.component';
import { CampaignPageComponent } from './presentation/sub-pages/campaign-page/campaign-page.component';
import { CountrySelectorComponent } from './core/components/country-selector/country-selector.component';
import { BillingPortalComponent } from './core/components/billing-portal/billing-portal.component';
import { ProductBannerComponent } from './core/components/product-banner/product-banner.component';
import { FeatureGridComponent } from './core/components/feature-grid/feature-grid.component';
import { FeatureGridTileComponent } from './core/components/feature-grid-tile/feature-grid-tile.component';
import { ExplanationBannerComponent } from './core/components/explanation-banner/explanation-banner.component';
import { ExplanationStepComponent } from './core/components/explanation-step/explanation-step.component';
import { HowDoesItWorkComponent } from './presentation/pages/how-does-it-work/how-does-it-work.component';
import { PricingComparePlansComponent } from './core/components/pricing-compare-plans/pricing-compare-plans.component';
import { PricingSubscriptionPlanComponent } from './core/components/pricing-subscription-plan/pricing-subscription-plan.component';
import { PricingComponent } from './presentation/pages/pricing/pricing.component';
import { PricingBannerComponent } from './core/components/pricing-banner/pricing-banner.component';
import { subscriptionPlansGetReducer } from './stores/subscriptionplans/subscriptionplans.reducer';
import { SubscriptionPlanGetEffects } from './stores/subscriptionplans/subscriptionplans.effects';
import { RegisterComponent } from './presentation/pages/registration/register.component';
import { RegistrationContactDetailsComponent } from './core/components/registration-contact-details/registration-contact-details.component';
import { SigninComponent } from './core/components/signin/signin.component';
import { RegisteredComponent } from './presentation/pages/registered/registered.component';
import { CheckoutBannerComponent } from './core/components/checkout-banner/checkout-banner.component';
import { NavigationComponent } from './core/components/navigation/navigation.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { CheckoutFailureComponent } from './core/components/checkout-failure/checkout-failure.component';
import { CheckoutSuccessComponent } from './core/components/checkout-success/checkout-success.component';
import { StoreUserDetailsComponent } from './core/components/store-user-details/store-user-details.component';
import { AcknowledgeDialogComponent } from './core/components/dialogs/acknowledge-dialog/acknowledge-dialog.component';
import { SpinnerDialogComponent } from './core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { CompanyLogoComponent } from './core/components/company-logo/company-logo.component';
import { CampaignLogoComponent } from './core/components/campaign-logo/campaign-logo.component';
import { StoreLocationLogoComponent } from './core/components/store-location-logo/store-location-logo.component';
import { BillingDetailsComponent } from './core/components/billing-details/billing-details.component';
import { BillingDetailsEditComponent } from './core/components/billing-details-edit/billing-details-edit.component';
import { PromotionComponent } from './core/components/promotion/promotion.component';
import { PromotionCreateComponent } from './core/components/promotion-create/promotion-create.component';
import { PromotionsPageComponent } from './presentation/sub-pages/promotions-page/promotions-page.component';
import { PromotionLogoComponent } from './core/components/promotion-logo/promotion-logo.component';
import { PromotionPageComponent } from './presentation/sub-pages/promotion-page/promotion-page.component';
import { GetStartedComponent } from './core/components/get-started/get-started.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxEchartsModule } from 'ngx-echarts';
import { StatsTotalMembersChartComponent } from './core/components/stats-total-members-chart/stats-total-members-chart.component';
import { StatsActiveCampaignChartComponent } from './core/components/stats-active-campaign-chart/stats-active-campaign-chart.component';
import { StatsActivePromotionChartComponent } from './core/components/stats-active-promotion-chart/stats-active-promotion-chart.component';
import { StatsActiveCampaignChartsComponent } from './core/components/stats-active-campaign-charts/stats-active-campaign-charts.component';
import { StatsActivePromotionChartsComponent } from './core/components/stats-active-promotion-charts/stats-active-promotion-charts.component';
import { GettingStartedComponent } from './presentation/pages/getting-started/getting-started.component';
import { DashboardComponent } from './presentation/pages/dashboard/dashboard.component';
import { OidcCallbackComponent } from './presentation/pages/security/oidc-callback/oidc-callback.component';
import { AuthorizationGuardService } from './services/authorization-guard.service';
import { BuySubscriptionPlanBannerComponent } from './core/components/buy-subscription-plan-banner/buy-subscription-plan-banner.component';
import { UnpaidInvoiceBannerComponent } from './core/components/unpaid-invoice-banner/unpaid-invoice-banner.component';
import { CurrentSubscriptionplanOrderComponent } from './core/components/current-subscriptionplan-order/current-subscriptionplan-order.component';
import { RegistrationTestimonialBannerComponent } from './core/components/registration-testimonial-banner/registration-testimonial-banner.component';
import { AboutComponent } from './presentation/pages/about/about.component';
import { BlogsComponent } from './presentation/pages/blogs/blogs.component';
import { CaseStudiesComponent } from './presentation/pages/case-studies/case-studies.component';
import { HelpCenterComponent } from './presentation/pages/help-center/help-center.component';
import { FaqComponent } from './presentation/pages/faq/faq.component';
import { ResourcesMenuComponent } from './core/components/resources-menu/resources-menu.component';
import { PrivacyPolicyComponent } from './presentation/pages/privacy-policy/privacy-policy.component';
import { UserTermsComponent } from './presentation/pages/user-terms/user-terms.component';
import { ClimatePledgeComponent } from './presentation/pages/climate-pledge/climate-pledge.component';
import { AccountComponent } from './core/components/account/account.component';
import { PartnerTermsComponent } from './presentation/pages/partner-terms/partner-terms.component';
import { ContactComponent } from './presentation/pages/contact/contact.component';
import { InvoicesComponent } from './core/components/invoices/invoices.component';
import { DashboardBannerComponent } from './core/components/dashboard-banner/dashboard-banner.component';
import { ProductBannerChefsSpecialComponent } from './core/components/product-banner-chefs-special/product-banner-chefs-special.component';
import { ProductBannerBreakfastComponent } from './core/components/product-banner-breakfast/product-banner-breakfast.component';
import { ProductBannerLunchComponent } from './core/components/product-banner-lunch/product-banner-lunch.component';
import { ProductBannerDinnerComponent } from './core/components/product-banner-dinner/product-banner-dinner.component';
import { ProductBannerHauteCuisineComponent } from './core/components/product-banner-haute-cuisine/product-banner-haute-cuisine.component';
import { ProductBannerPreparedComponent } from './core/components/product-banner-prepared/product-banner-prepared.component';
import { ProductBannerLevelUpComponent } from './core/components/product-banner-prepared-level-up/product-banner-prepared-level-up.component';
import { ProductBannerBuildConnectionsComponent } from './core/components/product-banner-prepared-build-connections/product-banner-prepared-build-connections.component';
import { ProductBannerKycComponent } from './core/components/product-banner-prepared-kyc/product-banner-prepared-kyc.component';
import { ProductBannerMadeForAnimalsComponent } from './core/components/product-banner-made-for-animals/product-banner-made-for-animals.component';
import { ProductBannerTakeOrderComponent } from './core/components/product-banner-take-order/product-banner-take-order.component';
import { ProductBannerLetsStartSavingComponent } from './core/components/product-banner-lets-start-saving/product-banner-lets-start-saving.component';
import { ShoutOutsComponent } from './core/components/shout-outs/shout-outs.component';
import { ShoutOutsPageComponent } from './presentation/pages/shout-outs-page/shout-outs-page.component';
import { AccessibilityComponent } from './presentation/pages/accessibility/accessibility.component';
import { StoreLocationOptionsEditComponent } from './core/components/store-location-options-edit/store-location-options-edit.component';
import { StoreLocationOptionsComponent } from './core/components/store-location-options/store-location-options.component';

@NgModule({
  declarations: [
    AppComponent,
    BottomMenuComponent,
    MenuComponent,
    UnauthorizedComponent,
    HomeComponent,
    BillingPageComponent,
    TrimValueAccessorDirective,
    CompanyPageComponent,
    CompanyEditComponent,
    CompanyComponent,
    StoreLocationsPageComponent,
    StoreLocationDetailPageComponent,
    StoreLocationComponent,
    StoreLocationEditComponent,
    ConfirmationDialogComponent,
    StoreLocationCreateComponent,
    CampaignCreateComponent,
    CampaignsPageComponent,
    CampaignComponent,
    CampaignPageComponent,
    CountrySelectorComponent,
    BillingPortalComponent,
    ProductBannerComponent,
    FeatureGridComponent,
    FeatureGridTileComponent,
    ExplanationBannerComponent,
    ExplanationStepComponent,
    HowDoesItWorkComponent,
    PricingComparePlansComponent,
    PricingSubscriptionPlanComponent,
    PricingComponent,
    PricingBannerComponent,
    RegisterComponent,
    RegistrationContactDetailsComponent,
    SigninComponent,
    RegisteredComponent,
    CheckoutBannerComponent,
    NavigationComponent,
    CheckoutFailureComponent,
    CheckoutSuccessComponent,
    StoreUserDetailsComponent,
    AcknowledgeDialogComponent,
    SpinnerDialogComponent,
    CompanyLogoComponent,
    CampaignLogoComponent,
    StoreLocationLogoComponent,
    BillingDetailsComponent,
    BillingDetailsEditComponent,
    PromotionComponent,
    PromotionCreateComponent,
    PromotionsPageComponent,
    PromotionLogoComponent,
    PromotionPageComponent,
    GetStartedComponent,
    StatsTotalMembersChartComponent,
    StatsActiveCampaignChartComponent,
    StatsActivePromotionChartComponent,
    StatsActiveCampaignChartsComponent,
    StatsActivePromotionChartsComponent,
    GettingStartedComponent,
    DashboardComponent,
    OidcCallbackComponent,
    BuySubscriptionPlanBannerComponent,
    UnpaidInvoiceBannerComponent,
    CurrentSubscriptionplanOrderComponent,
    RegistrationTestimonialBannerComponent,
    AboutComponent,
    BlogsComponent,
    CaseStudiesComponent,
    HelpCenterComponent,
    FaqComponent,
    ResourcesMenuComponent,
    PrivacyPolicyComponent,
    UserTermsComponent,
    ClimatePledgeComponent,
    AccountComponent,
    PartnerTermsComponent,
    ContactComponent,
    InvoicesComponent,
    DashboardBannerComponent,
    ProductBannerChefsSpecialComponent,
    ProductBannerBreakfastComponent,
    ProductBannerLunchComponent,
    ProductBannerDinnerComponent,
    ProductBannerHauteCuisineComponent,
    ProductBannerPreparedComponent,
    ProductBannerLevelUpComponent,
    ProductBannerBuildConnectionsComponent,
    ProductBannerKycComponent,
    ProductBannerMadeForAnimalsComponent,
    ProductBannerTakeOrderComponent,
    ProductBannerLetsStartSavingComponent,
    ShoutOutsComponent,
    ShoutOutsPageComponent,
    AccessibilityComponent,
    StoreLocationOptionsEditComponent,
    StoreLocationOptionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthConfigModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    StoreModule.forRoot({ appReducerKey: appReducer, subscriptionPlansGetReducerKey: subscriptionPlansGetReducer}),
    EffectsModule.forRoot([BusinessAccountEffects, SubscriptionPlanGetEffects]),
    NgxStripeModule.forRoot(environment.stripe_apiKey),
    extModules,
    FontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMatIntlTelInputComponent,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), 
    }),
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}, 
    AuthorizationGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
