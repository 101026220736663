import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PromotionModel } from 'src/app/models/promotion-model';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {
  @Input()
  promotion: PromotionModel;

  @Output()
  onDeletePressed = new EventEmitter();

  @Output()
  onActivatePressed = new EventEmitter();

  @Output()
  onDeactivatePressed = new EventEmitter();

  constructor() { 
    // Empty
  }

  ngOnInit(): void {
    // Empty
  }

  delete() {
    this.onDeletePressed.emit(null);
  }

  canShowActivateAction(): boolean {
    let result = this.promotion?.isInactive() || this.promotion?.isPendingActivation();
    return result;
  }

  canShowArchiveAction(): boolean {
    let result = this.promotion?.isActive();
    return result;
  }

  activate() {
    this.onActivatePressed.emit(null);
  }

  deactivate() {
    this.onDeactivatePressed.emit(null);
  }
}
