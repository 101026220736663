<div class="container" [formGroup]="promotionDetailsFormGroup">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title> Create New Deal </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row">
          <div fxFlex fxLayout="column" class="container-column">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Ex. My Deal"
                formControlName="name"
              />
              <mat-error
                *ngIf="
                promotionDetailsFormGroup.get('name').hasError('required') &&
                promotionDetailsFormGroup.get('name').touched
                "
              >
                <span>Deal name is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                promotionDetailsFormGroup.get('name').hasError('minlength') &&
                promotionDetailsFormGroup.get('name').touched
                "
              >
                <span>Deal name should have a minimum length of {{ minNameLength }}</span>
              </mat-error>
              <mat-error
                *ngIf="
                promotionDetailsFormGroup.get('name').hasError('maxlength') &&
                promotionDetailsFormGroup.get('name').touched
                "
              >
                <span>Deal name can have a maximum length of {{ maxNameLength }}</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Description</mat-label>
              <input
                matInput
                placeholder="Ex. My deal description"
                formControlName="description"
              />
              <mat-error
                *ngIf="
                promotionDetailsFormGroup
                    .get('description')
                    .hasError('required') &&
                    promotionDetailsFormGroup.get('description').touched
                "
              >
                <span>Description is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup.get('description').hasError('minlength') &&
                  promotionDetailsFormGroup.get('description').touched
                "
              >
                <span>Description should have a minimum length of {{minDescriptionLength}}</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup.get('description').hasError('maxlength') &&
                  promotionDetailsFormGroup.get('description').touched
                "
              >
                <span>Description can have a maximum length of {{maxDescriptionLength}}</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Start Date</mat-label>
              <input
                class="ng-trim-ignore"
                matInput
                [mtxDatetimepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mtx-datetimepicker-toggle
                matSuffix
                [for]="startDatePicker"
              ></mtx-datetimepicker-toggle>
              <mtx-datetimepicker #startDatePicker
                [twelvehour]="false"
                [timeInput]="false"
                [mode]="'auto'"
                [type]="'datetime'"
                [timeInterval]="1"
              ></mtx-datetimepicker>
              <mat-error
                *ngIf="
                promotionDetailsFormGroup
                    .get('startDate')
                    .hasError('required') &&
                    promotionDetailsFormGroup.get('startDate').touched
                "
              >
                <span>Start date is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup
                    .get('startDate')
                    .hasError('EndDateBeforeStartDate') &&
                  promotionDetailsFormGroup.get('startDate').touched
                "
              >
                <span>Start date must lie before end date</span>
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>End Date</mat-label>
              <input
                class="ng-trim-ignore"
                matInput
                [mtxDatetimepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mtx-datetimepicker-toggle
                matSuffix
                [for]="endDatePicker"
              ></mtx-datetimepicker-toggle>
              <mtx-datetimepicker #endDatePicker
                [twelvehour]="false"
                [timeInput]="false"
                [mode]="'auto'"
                [type]="'datetime'"
                [timeInterval]="1"
              ></mtx-datetimepicker>
              <mat-error
                *ngIf="
                promotionDetailsFormGroup
                    .get('endDate')
                    .hasError('required') &&
                    promotionDetailsFormGroup.get('endDate').touched
                "
              >
                <span>End date is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup
                    .get('endDate')
                    .hasError('EndDateBeforeStartDate') &&
                  promotionDetailsFormGroup.get('endDate').touched
                "
              >
                <span>End date must lie after start date</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex fxLayout="column" class="container-column">
            <mat-form-field appearance="outline">
              <mat-label>Reward Name</mat-label>
              <input
                matInput
                placeholder="Ex. Free Cup of Coffee"
                formControlName="promotionRewardName"
              />
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup
                    .get('promotionRewardName')
                    .hasError('required') &&
                  promotionDetailsFormGroup.get('promotionRewardName').touched
                "
              >
                <span>Reward Name is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup.get('promotionRewardName').hasError('minlength') &&
                  promotionDetailsFormGroup.get('promotionRewardName').touched
                "
              >
                <span>Reward Name should have a minimum length of {{ minNameLength }}</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup.get('promotionRewardName').hasError('maxlength') &&
                  promotionDetailsFormGroup.get('promotionRewardName').touched
                "
              >
                <span>Reward Name can have a maximum length of {{maxNameLength}}</span>
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Reward Description</mat-label>
              <input
                matInput
                placeholder="Ex. Thank you for supporting us."
                formControlName="promotionRewardDescription"
              />
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup
                    .get('promotionRewardDescription')
                    .hasError('required') &&
                  promotionDetailsFormGroup.get('promotionRewardDescription')
                    .touched
                "
              >
                <span>Reward description is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup.get('promotionRewardDescription').hasError('minlength') &&
                  promotionDetailsFormGroup.get('promotionRewardDescription').touched
                "
              >
                <span>Reward description should have a minimum length of {{ minDescriptionLength }}</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup.get('promotionRewardDescription').hasError('maxlength') &&
                  promotionDetailsFormGroup.get('promotionRewardDescription').touched
                "
              >
                <span>Reward description can have a maximum length of {{ maxDescriptionLength }}</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Voucher Valid From Date</mat-label>
              <input
                class="ng-trim-ignore"
                matInput
                [mtxDatetimepicker]="voucherStartDatePicker"
                formControlName="startDateVoucher"
              />
              <mtx-datetimepicker-toggle
                matSuffix
                [for]="voucherStartDatePicker"
              ></mtx-datetimepicker-toggle>
              <mtx-datetimepicker #voucherStartDatePicker
                [twelvehour]="false"
                [timeInput]="false"
                [mode]="'auto'"
                [type]="'datetime'"
                [timeInterval]="1"
              ></mtx-datetimepicker>
              <mat-error
                *ngIf="
                promotionDetailsFormGroup
                    .get('startDateVoucher')
                    .hasError('required') &&
                    promotionDetailsFormGroup.get('startDateVoucher').touched
                "
              >
                <span>Voucher Valid From date is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup
                    .get('startDateVoucher')
                    .hasError('EndDateBeforeStartDate') &&
                  promotionDetailsFormGroup.get('startDateVoucher').touched
                "
              >
                <span>Voucher valid from date must lie before voucher valid until date</span>
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Voucher Valid Until Date</mat-label>
              <input
                class="ng-trim-ignore"
                matInput
                [mtxDatetimepicker]="voucherEndDatePicker"
                formControlName="endDateVoucher"
              />
              <mtx-datetimepicker-toggle
                matSuffix
                [for]="voucherEndDatePicker"
              ></mtx-datetimepicker-toggle>
              <mtx-datetimepicker #voucherEndDatePicker
                [twelvehour]="false"
                [timeInput]="false"
                [mode]="'auto'"
                [type]="'datetime'"
                [timeInterval]="1"
              ></mtx-datetimepicker>
              <mat-error
                *ngIf="
                promotionDetailsFormGroup
                    .get('endDateVoucher')
                    .hasError('required') &&
                    promotionDetailsFormGroup.get('endDateVoucher').touched
                "
              >
                <span>Voucher Valid Until date is required</span>
              </mat-error>
              <mat-error
                *ngIf="
                  promotionDetailsFormGroup
                    .get('endDateVoucher')
                    .hasError('EndDateBeforeStartDate') &&
                  promotionDetailsFormGroup.get('endDateVoucher').touched
                "
              >
                <span>Voucher valid until date must lie after voucher valid from date</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="end">
          <button mat-icon-button (click)="cancel()">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-icon-button (click)="save()">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  