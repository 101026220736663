import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { countryList } from '../country-selector/country-list';
import { Country } from '../country-selector/country-model';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
  @Input()
  businessAccount: BusinessAccountModel;

  @Input()
  companyDetailsFormGroup: UntypedFormGroup;

  @Output()
  onCancelPressed = new EventEmitter();

  @Output()
  onSavePressed = new EventEmitter();

  ngOnInit(): void {
    if(this.businessAccount !== null){
      this.companyDetailsFormGroup.patchValue({'id': this.businessAccount.company.id});
      this.companyDetailsFormGroup.patchValue({'companyName': this.businessAccount.company.name});
      this.companyDetailsFormGroup.patchValue({'description': this.businessAccount.company.description});
      this.companyDetailsFormGroup.patchValue({'website': this.businessAccount.company.url});
      this.companyDetailsFormGroup.patchValue({'companyEmail': this.businessAccount.company.email});
    }
  }

  cancel() {
    this.onCancelPressed.emit(null);
  }

  save() {
    this.onSavePressed.emit(null);
  }
}
