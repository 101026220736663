<div class="container" fxLayout="row" fxLayoutAlign="start">
    <div *ngIf="readMode;">
      <div class="container-campaign" fxFlex>
        <app-campaign
          [campaign]="(viewModel$ | async)?.campaign"
          (onDeletePressed)="onDelete($event)"
          (onActivatePressed)="onActivate($event)"
        ></app-campaign>
      </div>
    </div>
    <div class="container-campaign-logo">
      <app-campaign-logo [campaign]="(viewModel$ | async)?.campaign"></app-campaign-logo>
    </div>
  </div>