<div class="container">
  <mat-card
    appearance="outlined"
    class="column-card mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Can we take your order?</mat-card-title>
        <mat-icon>menu_book</mat-icon>
      </mat-card-title-group>
    </mat-card-header>
    <div *ngIf="isXLarge(); else largeContentImage">
      <img
        src="/assets/img/banners/xlarge/product-banner-take-order-xl.webp"
        alt="breakfast-bring-in-new-customers" 
      />
    </div>
    <mat-card-content>
      <h2>
        The Planet VG Chefs are ready to help you and your team to start
        connecting.
      </h2>
    </mat-card-content>
    <mat-card-actions class="container-card-actions">
      <button mat-raised-button color="primary" [routerLink]="['/pricing']">
        <mat-icon>shopping_basket</mat-icon>
        Place Order
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #largeContentImage>
  <div *ngIf="isLarge(); else mediumContentImage">
    <img
      src="/assets/img/banners/large/product-banner-take-order-l.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #mediumContentImage>
  <div *ngIf="isMedium(); else smallContentImage">
    <img
      src="/assets/img/banners/medium/product-banner-take-order-m.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #smallContentImage>
  <div *ngIf="isSmall(); else xSmallContentImage">
    <img
      src="/assets/img/banners/small/product-banner-take-order-s.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img
      src="/assets/img/banners/xsmall/product-banner-take-order-xs.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

