import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateCampaignModel } from 'src/app/models/create-campaign-model';
import { CreateCampaignComponentStore } from 'src/app/stores/components/campaign-create/create-campaign.component-store';
import { EndDateAfterStartDate } from '../../validators/start-end-date.validator';
import { VoucherDuration } from 'src/app/models/voucher-duration-enum';
import { VoucherDurationP1M, VoucherDurationP1Y, VoucherDurationP2Y, VoucherDurationP3M, VoucherDurationP3Y, VoucherDurationP6M } from 'src/app/models/campaign-model';
import { ValidationException } from '../../exceptions/validation-exception';
import { MaximumActiveCampaignsExceededException } from '../../exceptions/maximum-active-campaigns-exceeded-exception';

interface VoucherDurationViewModel {
  value: VoucherDuration,
  viewValue: string,
}

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.scss']
})
export class CampaignCreateComponent implements OnInit {
  @Input()
  campaignDetailsFormGroup: UntypedFormGroup;

  @Output()
  onCancelPressed = new EventEmitter();

  @Output()
  onSavePressed = new EventEmitter();

  public minNameLength : number = 3;
  public maxNameLength : number = 32;
  public minDescriptionLength : number = 3;
  public maxDescriptionLength : number = 256;

  private ngUnsubscribeState = new Subject<void>();
  private companyId: string;



  voucherDurations: VoucherDurationViewModel[] = [
    { value: VoucherDuration.MONTH, viewValue: VoucherDurationP1M },
    { value: VoucherDuration.MONTHS3, viewValue: VoucherDurationP3M },
    { value: VoucherDuration.MONTHS6, viewValue: VoucherDurationP6M },
    { value: VoucherDuration.YEAR, viewValue: VoucherDurationP1Y },
    { value: VoucherDuration.YEARS2, viewValue: VoucherDurationP2Y },
    { value: VoucherDuration.YEARS3, viewValue: VoucherDurationP3Y },
  ];

  constructor(private formBuilder: UntypedFormBuilder,
    private location: Location,
    private createCampaignComponentStore: CreateCampaignComponentStore,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.companyId = params.companyId;
    });

    let startDate = new Date();
    let endDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    endDate.setDate(endDate.getDate() + 90);

    this.campaignDetailsFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required],
      stampsObjective: ['3', [Validators.required, Validators.min(1)]],
      campaignRewardName: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      campaignRewardDescription: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      voucherDuration: [VoucherDuration.YEAR, Validators.required],
    }, {
      validator: EndDateAfterStartDate('startDate', 'endDate')
    });

    this.createCampaignComponentStore.campaign$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.snackBar.open("Campaign created successfully. Don't forget to add a campaign logo.", "Dismiss");
        this.router.navigate(['/companies/' + result.company.id +'/campaigns/' + result.id]);
      }
    });

    this.createCampaignComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if(errorMessage !== null && errorMessage !== undefined){
        if(errorMessage instanceof ValidationException){
            const validationViolationMessage = errorMessage.violations.map(v => v.field + ' ' + v.message).join("\n");
            this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else if(errorMessage instanceof MaximumActiveCampaignsExceededException){
          this.snackBar.open("Can't create a new loyalty campaign. You have already reached the maximum.", "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });
  }

  cancel() {
    this.location.back();
  }

  save() {
    this.campaignDetailsFormGroup.markAllAsTouched();
    
    if (this.campaignDetailsFormGroup.valid) {
      var createCampaign = new CreateCampaignModel();
      createCampaign.name = this.campaignDetailsFormGroup.value.name;
      createCampaign.description = this.campaignDetailsFormGroup.value.description;
      createCampaign.startDate = this.campaignDetailsFormGroup.value.startDate;
      createCampaign.endDate = this.campaignDetailsFormGroup.value.endDate;
      createCampaign.stampsObjective = this.campaignDetailsFormGroup.value.stampsObjective;
      createCampaign.campaignRewardName = this.campaignDetailsFormGroup.value.campaignRewardName;
      createCampaign.campaignRewardDescription = this.campaignDetailsFormGroup.value.campaignRewardDescription;
      createCampaign.voucherDuration = this.campaignDetailsFormGroup.value.voucherDuration.toString();

      this.createCampaignComponentStore.createCampaign({ companyId: this.companyId, createCampaignModel: createCampaign });
    }
  }

  onStampsSliderChanged($event) {
    this.campaignDetailsFormGroup.patchValue({ stampsObjective: $event.value });
  }
}
