import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'app-registration-contact-details',
  templateUrl: './registration-contact-details.component.html',
  styleUrls: ['./registration-contact-details.component.scss']
})
export class RegistrationContactDetailsComponent implements OnInit {
  @Input()
  registrationDetailsFormGroup: UntypedFormGroup;

  @Output()
  onRegisterPressed = new EventEmitter();
  
  constructor() { 
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }

  register() {
    this.onRegisterPressed.emit(null);
  }
}
