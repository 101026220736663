<div class="container">
  <div class="container-banner">
    <h1>FAQ</h1>
    <div class="banner-content">
      <h2>
        We hope you'll find your answer here. If not, then feel free to
        <a href="/contact">contact</a> the Planet VG Chefs.
      </h2>
    </div>
  </div>

  <mat-accordion multi>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What kind of business is Planet VG most successful for?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Planet VG's deals and loyalty campaigns are successful for climate
        conscious and vegan businesses with a highly repeatable product or
        service.
      </p>
      <ul>
        <li>Cafes</li>
        <li>Coffeeshops</li>
        <li>Restaurants</li>
        <li>Fast Food Restaurants</li>
        <li>Retail Stores</li>
        <li>Nail Salons</li>
        <li>Hair & Beauty Salons</li>
        <li>Health Services</li>
        <li>Fitness Centers</li>
        <li>Yoga Studios</li>
        <li>Bakeries</li>
        <li>B&Bs</li>
        <li>Hotels</li>
        <li>Market Vendors</li>
        <li>Food Trucks</li>
        <li>Juice Bars</li>
        <li>Ice Cream Vendors</li>
        <li>Spas</li>
        <li>Car Washes</li>
        <li>Farmers Markets</li>
        <li>And many more ...</li>
      </ul>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What's the difference between the Planet VG app and the Planet VG
          Store app?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The Planet VG app is the app for your customers. Using this app your
        customers can grab deals, join loyalty campaigns, collect stamps, and
        vouchers. They can also manage their club memberships.
      </p>
      <p>
        The Planet VG Store app is the app for your employees. Using this app
        your employees can issue stamps for a specific loyalty campaign or
        redeem vouchers.
      </p>
      <p><em>And, while we are at it;</em></p>
      <p>
        The Planet VG Partner Portal is the portal where
        you, as a partner, can manage your deals, loyalty campaigns, store
        locations and your company. You can also manage your subscription within
        the partner portal.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          How long does it take to set up a deal?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        It's very easy to create a deal for your customers. Make sure you have a
        nice promotional image at hand. This image will be the first thing your customers will see.
      </p>
      <p>
        Once you have become a partner via the
        <a href="/register">Registration page</a> and filled out your company
        details, navigate to <strong>Deals</strong> and press
        <strong>New</strong>.
      </p>
      <p>
        Your subscription plan determines how many deals you can create and
        activate.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          How long does it take to set up a loyalty campaign?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        It's very easy to create a loyalty campaign for your customers. Make
        sure you have a nice promotional image at hand. This image will be the first thing your customers will see.
      </p>
      <p>
        Once you have become a partner via the
        <a href="/register">Registration page</a> and filled out your company
        details, navigate to <strong>Campaigns</strong> and press
        <strong>New</strong>.
      </p>
      <p>
        Your subscription plan determines how many loyalty campaigns you can
        create and activate.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> How does the free trial work? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        As a new partner you will receive a <strong>30 day free trial</strong> or
        <strong>Amuse-Bouche</strong>, when you buy a subscription plan. When the trial period ends, you will
        receive your first invoice.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> How are payments processed? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        When you buy a subscription plan, you'll need to provide a payment method.
        We use <a href="https://www.stripe.com">Stripe</a> for handling all
        payments. This way we don't store your payment data. How your data is
        handled can be found in our
        <a href="/privacy-polict">privacy policy</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> When will I be billed? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You will receive your first invoice, when the <strong>30 day free trial</strong> ends. Depending on your subscription plan you will either be
        billed <strong><em>monthly</em></strong> or <strong><em> annually (yearly)</em></strong
        >.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What is your privacy policy? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can find our privacy policy on the
        <a href="/privacy-policy">Privacy Policy page</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What are the terms of service? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can find our user terms on the
        <a href="/user-terms">User Terms page</a> and the partner terms on the
        <a href="/partner-terms">Partner Terms page</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Are there any setup costs? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>No, there are no setup costs.</p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I change my plan or cancel it at any time?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Yes. You can change (upgrade or downgrade) your plan at any time.</p>
      <p>You will not lose your members when downgrading.</p>
      <p>
        <em>Be aware</em>. When you downgrade your plan, you will lose certain
        features. Active deals and campaigns will be deactivated. After the downgrade, you decide which deals 
        and campaigns you want to reactivate.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What does Planet VG do for the climqte change? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Planet VG is committed to reduce the CO2 computer servers emit. We run our servers on renewable energy. And promote a plant-based lifestyle.
      </p>
      <p>See our <a href="/climate-pledge">Climate Pledge</a> for more details.</p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Still have questions? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        If you still have questions, feel free to contact us at any time. You
        can find the contact form on this page
        <a href="/contact">Contact page</a>.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<app-bottom-menu></app-bottom-menu>
