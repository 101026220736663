<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Store Location Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="row">
        <div class="container-column" fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput readonly value="{{ storeLocation?.name }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput readonly value="{{ storeLocation?.description }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <input matInput readonly value="{{ storeLocation?.toStoreLocationTypeString() }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <input matInput readonly value="{{ storeLocation?.toStoreLocationCategoryString() }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input matInput readonly value="{{ storeLocation?.phoneNumber }}" />
          </mat-form-field>
          
        </div>
        <div class="container-column" fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>Address Line 1</mat-label>
            <input
              matInput
              readonly
              value="{{ storeLocation?.addressLine1 }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Address Line 2</mat-label>
            <input
              matInput
              readonly
              value="{{ storeLocation?.addressLine2 }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Postal Code</mat-label>
            <input matInput readonly value="{{ storeLocation?.postalCode }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput readonly value="{{ storeLocation?.city }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
            <input matInput readonly value="{{ storeLocation?.country }}" />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="column" class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input
              matInput
              readonly
              value="{{ storeLocation?.storeLocationStatus }}"
            />
          </mat-form-field>
          <app-store-location-options fxLayout="column" class="container-column" [storeLocationOptions]="storeLocation?.storeLocationOptions"></app-store-location-options>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div
        class="container-actions"
        fxLayout="row"
        fxLayoutAlign="end"
        *ngIf="canShowActions(); else showActivateActions"
      >
        <button
          mat-icon-button
          (click)="edit()"
          matTooltip="Edit the storelocation details"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="delete()"
          matTooltip="Archive the storelocation"
        >
          <mat-icon>archive</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #showActivateActions>
  <div class="container-actions" fxLayout="row" fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="activate()"
      matTooltip="Activate the storelocation"
    >
      <mat-icon>play_circle</mat-icon>
    </button>
  </div>
</ng-template>
