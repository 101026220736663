<div class="container">
    <h2>Receive an amuse-bouche when you buy a subscription.</h2>
    <h2>Our chefs have prepared a one month free trial for you.</h2>
    <div [ngClass]="{
        'container-quote-xlarge': isXLarge(),
        'container-quote-large': isLarge(),
        'container-quote-medium': isMedium(),
        'container-quote-small': isSmall(),
        'container-quote-xsmall': isXSmall()
      }" >
        Did you know that our servers run on renewable energy?<mat-icon color="primary">mode_comment_outline</mat-icon>
    </div>
</div>
