<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Deal Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex fxLayout="column" class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput readonly value="{{ promotion?.name }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput readonly value="{{ promotion?.description }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              readonly
              value="{{ promotion?.startDate | date : 'dd/MM/yyyy HH:mm' }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              matInput
              readonly
              value="{{ promotion?.endDate | date : 'dd/MM/yyyy HH:mm' }}"
            />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="column" class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Reward Title</mat-label>
            <input
              matInput
              readonly
              value="{{ promotion?.promotionReward.name }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Reward Description</mat-label>
            <input
              matInput
              readonly
              value="{{ promotion?.promotionReward.description }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Voucher Valid From Date</mat-label>
            <input
              matInput
              readonly
              value="{{
                promotion?.startDateVoucher | date : 'dd/MM/yyyy HH:mm'
              }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Voucher Valid Until Date</mat-label>
            <input
              matInput
              readonly
              value="{{
                promotion?.endDateVoucher | date : 'dd/MM/yyyy HH:mm'
              }}"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput readonly value="{{ promotion?.promotionStatus }}" />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions" fxLayout="row" fxLayoutAlign="end">
        <div *ngIf="canShowArchiveAction()">
          <button mat-icon-button (click)="delete()">
            <mat-icon>archive</mat-icon>
          </button>
        </div>
        <div *ngIf="canShowActivateAction()">
          <button
          mat-icon-button
          (click)="activate()"
          matTooltip="Activate this deal"
        >
          <mat-icon>play_circle</mat-icon>
        </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>