<div [ngClass]="{
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <app-pricing-banner></app-pricing-banner>

  <div
  [ngClass]="{
    'container-options-large': isLarge(),
    'container-options-medium': isMedium(),
    'container-options-small': isSmall(),
    'container-options-xsmall': isXSmall()
  }"
    *ngIf="!(subscriptionPlansState$ | async)?.loading"
  >
    <mat-radio-group [(ngModel)]="selectedRecurringType">
      <mat-radio-button
        *ngFor="let recurringType of recurringTypes"
        [value]="recurringType"
        >{{ recurringType }}</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div
  [ngClass]="{
    'container-subscription-plans-large': isLarge(),
    'container-subscription-plans-medium': isMedium(),
    'container-subscription-plans-small': isSmall(),
    'container-subscription-plans-xsmall': isXSmall()
  }"
  >
    <div
      *ngIf="
        (subscriptionPlansState$ | async)?.loading;
        else showSubscriptionPlans
      "
    >
      <div
      [ngClass]="{
        'container-spinner-large': isLarge(),
        'container-spinner-medium': isMedium(),
        'container-spinner-small': isSmall(),
        'container-spinner-xsmall': isXSmall()
      }"
      >
        <mat-spinner diameter="80"></mat-spinner>
      </div>
    </div>
  </div>
</div>
<div class="container-vg">
  (vg) = vegan and prepared with renewable energy.
</div>
<div *ngIf="!(hasBusinessAccount$ | async)">
  <app-bottom-menu></app-bottom-menu>
</div>

<ng-template #showSubscriptionPlans>
  <div
  [ngClass]="{
    'container-subscription-plan-large': isLarge(),
    'container-subscription-plan-medium': isMedium(),
    'container-subscription-plan-small': isSmall(),
    'container-subscription-planp-xsmall': isXSmall()
  }"

    
    *ngFor="let subscriptionPlan of subscriptionPlans$ | async; index as i"
  >
    <app-pricing-subscription-plan
      [subscriptionPlan]="subscriptionPlan"
      [recurringType]="selectedRecurringType"
    ></app-pricing-subscription-plan>
  </div>
</ng-template>
